import React, { useEffect, useState } from "react";
import { Link } from "gatsby";
import { useWindow } from "context/windowContext";
import { useCart } from "context/cartContext";

import Main from "components/Layout/main";
import Button from "components/UI/button";
import StaticPageMeta from "components/UI/staticPageMeta";

import "styles/thank-you-page.scss";

const ThankYouWrapper = (props) => {
    const { location, history } = useWindow();
    const { removeCart } = useCart();

    const [idValue, setIdValue] = useState('');
    const [isPrescription, setIsPrescription] = useState('');

    useEffect(() => {
        removeCart();

        const currLocationParams = new URLSearchParams(location.search);

        setIdValue(currLocationParams.get("orderId"));
        setIsPrescription(currLocationParams.get("isPrescription") === "true");

        currLocationParams.delete("orderId");
        currLocationParams.delete("isPrescription");

        const newUrl = `${location.origin}${location.pathname}${currLocationParams.toString()}`;

        history.replaceState({}, "", newUrl);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className="thank-you-content">
            <h1 className="thank-you-content__title typography__h2">
                Bedankt voor uw bestelling
            </h1>
            <h2 className="thank-you-content__description">
                Uw bestelling:{" "}
                {
                    idValue ?
                        <Link to={`/my-account?orderId=${idValue}`}>{idValue}</Link> :
                        <Link to="/">###</Link>
                }
            </h2>
            <h2 className="thank-you-content__description">
                {
                    isPrescription ?
                        "De arts bekijkt je medische vragenlijst om te beoordelen of een recept geschikt voor je is. Je kunt de status en eventuele follow-up volgen via je account." :
                        "Je kan de status van je bestelling raadplegen in het gedeelte Bestellingen op je accountpagina"
                }
            </h2>
            <Button value="Volg mijn bestelling op" href="/my-account" type="dark"/>
            <Button value="Verder winkelen" href="/" type="light"/>
        </div>
    );
};
const ThankYou = (props) => {
    return (
        <>
            <StaticPageMeta pageName="Thank you"/>
            <Main>
                <ThankYouWrapper {...props} />
            </Main>
        </>
    );
};
export default ThankYou;
